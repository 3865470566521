<template>
  <v-row @click="dialog = true" class="d-flex">
    <drug-result-dialog
      @close="dialog = false"
      :active="dialog"
      :drugData="drugData"
    />    
      <v-icon :color="color" large class="mx-2">mdi-circle</v-icon>

      <slot></slot>
    
  </v-row>
</template>

<script>
import DrugResultDialog from "@/components/mylogy/pgx_results/DrugResultDialog.vue";
import DrugResultMixin from "@/mixins/DrugResultMixin.vue";
export default {
  mixins: [DrugResultMixin],
  components: { DrugResultDialog },
  data() {
    return {
      dialog: false
    };
  }
};
</script>
