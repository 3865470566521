var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('reactive-base',{staticClass:"d-flex",attrs:{"app":"drugs","url":_vm.url,"credentials":_vm.credentials,"type":"_doc"}},[_c('v-icon',{staticClass:"mr-4 mt-1",attrs:{"large":"","color":"white"}},[_vm._v("mdi-magnify")]),_c('data-search',{staticClass:"grow",attrs:{"componentId":"DrugSearchSensor","placeholder":_vm.$t('buscar_por_medicamento'),"showClear":false,"showIcon":false,"dataField":['names', 'names.autosuggest']},scopedSlots:_vm._u([{key:"render",fn:function(ref){
      var error = ref.error;
      var loading = ref.loading;
      var ref_downshiftProps = ref.downshiftProps;
      var isOpen = ref_downshiftProps.isOpen;
      var highlightedIndex = ref_downshiftProps.highlightedIndex;
      var getItemProps = ref_downshiftProps.getItemProps;
      var getItemEvents = ref_downshiftProps.getItemEvents;
      var suggestions = ref.data;
return _c('v-sheet',{class:{ suggestions: isOpen },attrs:{"width":"100%","rounded":"sm","elevation":"16"}},[(isOpen)?_c('ul',_vm._l((_vm.renderSuggestions(suggestions || [])),function(suggestion){return _c('li',_vm._b({key:suggestion._id,staticClass:"px-8 py-5",staticStyle:{"{ background-color":"highlightedIndex ? 'grey' : 'transparent' }"}},'li',getItemProps({ item: suggestion }),false),[_c('drug-input-search-result',{attrs:{"drug":suggestion.value}},[_vm._v(" "+_vm._s(suggestion.label)+" ")])],1)}),0):_vm._e()])}}])}),_c('v-btn',{staticClass:"d-none d-sm-inline-flex ma-auto ml-6",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('moreFilters')}}},[_vm._v(" Más filtros ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }