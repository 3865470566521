<template>
  <v-container id="genetic-profile" fluid tag="section">
    <fondo-custom></fondo-custom>

    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-row class="justify-center">
          <v-col cols="12" sm="6" align-self="center">
            <page-top-title>
              <template v-slot:toptitle-icon>mdi-dna</template>
              <template v-slot:toptitle-text>{{ $t("perfil farmacogenetico") }} </template>
            </page-top-title>
          </v-col>

          <v-col cols="12" sm="6" align-self="center">
            <v-row justify="space-around" justify-md="end" class="mt-sm-6">
              <input
                type="file"
                @change="uploadGeneticData"
                ref="loadInput"
                style="display: none"
              />
              <v-btn
                v-if="$user.get().role == 'advanced'"
                @click="triggerUpload"
                color="primary"
                class="mx-0 mx-sm-2"
              >
                <v-icon class="mt-n1 ml-n2 mr-2">mdi-upload</v-icon>
                <span>{{ $t("cargar_datos") }}</span>
              </v-btn>
              <v-btn
                color="primary"
                :disabled="overlay"
                :loading="overlay"
                @click="requestPDFReport"
              >
                <v-icon class="mt-n1 ml-n2 mr-2">mdi-file-pdf-outline</v-icon>
                {{ $t("descargar_informe") }}
              </v-btn>
              <v-dialog v-model="overlay" hide-overlay persistent width="500">
                <v-card color="primary" dark>
                  <v-card-text class="white--text text-h3">
                    {{ $t("por_favor_espere_mientras_se_prepara_su_informe")
                    }}<v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0 mt-4"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>
        </v-row>
        
        <v-row>
          <!-- FILTROS -->
          <v-row class="sticky filters z-index-99">
            <v-container class="" fluid>
              <v-card class="pa-0" rounded="lg" elevation="12">
                <v-card-title class="solid-heading rounded-lg py-0 px-4 px-sm-10  mb-0">
                  <drug-input-search @moreFilters="moreFilters = !moreFilters" />
                </v-card-title>
                <v-card-text class="py-0">
 
                    <v-expansion-panel >
                      <v-expansion-panel-content  v-if="moreFilters">
                        <v-form ref="form">
                          <v-container class="py-0 mb-n6 mt-4">
                            <v-row justify="center">
                              <v-col cols="12" sm="8">
                                <v-select
                                  v-model="selectedPanel"
                                  :items="sortedPanels"
                                  item-text="display"
                                  item-value="value"
                                  :label="$t('panel')"
                                  rounded
                                  outlined
                                  background-color="white"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-autocomplete
                                  v-model="search"
                                  :items="sortedGenes"
                                  :label="$t('gen')"
                                  item-text="display"
                                  solo
                                  background-color="white"
                                  rounded
                                  outlined
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-card-text>
              </v-card>
            </v-container>
          </v-row>
          <!-- RESULTADOS -->
          <v-row
            v-for="geneClass in Object.keys(geneClasses)"
            :key="geneClass"
            class="gradient-bucle"
          >
            <v-container fluid>
              <v-card class="my-2 pa-0" rounded="lg">
                <v-card-title class="solid-heading pt-2 rounded-lg">
                  <v-col cols="1">
                    <h5 class="text-h3 white--text">1</h5>
                  </v-col>
                  <v-col>
                    <p class="white--text">{{ $t("grupo_de_genes") }}</p>
                    <div
                      class="
                        card-title
                        text-h3
                        mt-n3
                        font-weight-light
                        white--text
                      "
                    >
                      {{ $t(geneClass) }}
                    </div>
                  </v-col>
                </v-card-title>
                <v-container
                  fluid
                  v-for="entry in getGenesByClass(geneClass)"
                  :key="entry[0]"
                  justify="space-between"
                >
                  <div
                    v-if="entry[0] != geneClass"
                    class="card-title text-h3 font-weight-light"
                  >
                    {{ $t(entry[0]) }}
                  </div>
                  <v-list class="gene-container" rounded>
                    <gene-card
                      v-for="gene in entry[1]"
                      :key="gene.gene_id"
                      :gene="gene"
                    />
                  </v-list>
                </v-container>

                <v-card-actions>
                  <v-divider></v-divider>
                  <v-btn
                    color="primary"
                    outlined
                    @click="
                      geneClasses[geneClass].show = !geneClasses[geneClass].show
                    "
                    rounded
                  >
                    {{ $t("leyenda") }}
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="geneClasses[geneClass].show">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            v-for="color in geneClasses[geneClass].colors"
                            :key="color"
                            cols="12"
                            md="6"
                            class="d-flex"
                          >
                            <v-icon
                              large
                              :color="color"
                              class="align-self-start mt-n2 mr-2"
                              >mdi-circle</v-icon
                            >
                            {{ $t(`legend_${geneClass}_${color}`) }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-container>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Pusher from "pusher-js";
import axios from "axios";
import appbase from "@/services/appbase.js";
import { getMyGenotype } from "@/models/GenotypeModel.js";
import { getMyPGXGenes } from "@/models/PGXGenesModel.js";
import { mapGetters, mapActions } from "vuex";
import GeneCard from "@/components/mylogy/GeneCard.vue";
import FondoCustom from "@/components/mylogy/FondoCustom.vue";
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import DrugInputSearch from "@/components/mylogy/pgx_results/DrugInputSearch.vue";

var slugify = require("slugify");

export default {
  components: { GeneCard, FondoCustom, PageTopTitle, DrugInputSearch },
  data() {
    return {
      overlay: false,
      pusher: null,
      channel: null,
      moreFilters: false,
      show: false,
      genes: [],
      colors: ["grey", "#b0e0e6", "green", "yellow", "orange", "red"],
      geneClasses: {
        Phase: {
          show: true,
          colors: ["grey", "green", "yellow", "orange", "red"],
        },
        Transporter: {
          show: true,
          colors: ["grey", "green", "yellow", "red"],
        },
        Receptor: {
          show: true,
          colors: ["grey", "green", "yellow", "red"],
        },
        Pathogenic: {
          show: true,
          colors: ["grey", "green", "yellow", "red"],
        },
      },
      search: "",
      selectedPanel: "CARD60",
      dialog: false,
    };
  },
  computed: {
    ...mapGetters("pgx", [
      "getPgx",
      "getDrugResult",
      "getGeneResult",
      "getReportData",
    ]),
    ...mapGetters("app", ["apiSufix"]),
    ...mapGetters("auth", ["currentUser"]),
    sortedPanels() {
      let self = this;
      const visiblePanels = [
        "ANTIDEMENCIA",
        "ANSIOLITICOS",
        "ANALGESICOS",
        "ANESTESICOS",
        "ANTI-TDAH",
        "ANTIPARKINSONIANOS",
        "ANTIPSICOTICOS",
        "CARD60",
        "ANTIDEPRESIVOS",
        "DETOX",
        "ANTICONVULSIVOS",
        "CARD5",
        "CARD12",
        "CARD48",
      ];
      return [
        ...new Set(
          [].concat.apply(
            [],
            this.genes.map((g) => {
              return g.panels;
            })
          )
        ),
      ]
        .map((e) => {
          return {
            display: self.$t(e),
            value: e,
          };
        })
        .sort((a, b) =>
          a.display > b.display ? 1 : b.display > a.display ? -1 : 0
        )
        .filter((p) => visiblePanels.includes(p.value));
    },
    sortedGenes() {
      let self = this;
      return self.genes
        .map((e) => e._id.toUpperCase())
        .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    },
    filteredGenes() {
      let self = this;
      let search = this.search || "";
      return self.genes
        .map((e) => {
          e.result = self.colors[e.code];
          return e;
        })
        .filter((g) => {
          return (
            g.panels.includes(self.selectedPanel) &&
            slugify(g.gene, { lower: true }).includes(
              slugify(search, { lower: true })
            )
          );
        });
    },
  },
  mounted() {
    let self = this;
    self.pusher = new Pusher("413f5240c36a765dd122", {
      cluster: "eu",
    });
    self.channel = self.pusher.subscribe(self.currentUser.id);
    self.loadGenes();
  },
  methods: {
    ...mapActions("pgx", ["setGenotype", "setPGXGenes"]),
    loadGenes() {
      let self = this;
      appbase.genes
        .search({
          body: {
            size: 1000,
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "has_pgx.keyword": "y",
                    },
                  },
                ],
              },
            },
          },
        })
        .then(function (response) {
          self.genes = response.hits.hits.map((h) => {
            return {
              ...self.getGeneResult(h._source.gene_id),
              ...{ _id: h._source.gene_id, panels: h._source.panels },
              ...h._source,
            };
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    triggerUpload() {
      this.$refs.loadInput.click();
    },
    requestPDFReport() {
      let self = this;
      self.overlay = true;
      const templateId =
        "16e5d5bf97544a2e37eda05c35aec36f9694eb2cc07a5eb80319adc7a60dcc87";
      axios
        .post(
          `https://render.carbone.io/render/${templateId}`,
          {
            convertTo: "pdf",
            data: self.getReportData,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "carbone-version": "2",
              Authorization:
                "Bearer eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiIzNTA4IiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6MjIzODQ5MTA5NiwiZGF0YSI6eyJpZEFjY291bnQiOjM1MDh9fQ.AOutG3GWTYrd4t1Fh7fWSaGVPU-16COsHBf3IH5XFyG0xUopAc3w-L7hiWrle5TzT-klmNs7MUymVpILv2Q6yprrAVMrS7cAVuX2aB-C3bm2IUftL_oCjnD9bJpFhF7jeE5X4AKQuNncN1ZqILmZHJt7zDqs4-SqmRpB5a4IvncOm0Y4",
            },
          }
        )
        .then((res) => {
          self.overlay = false;
          window.open(
            `https://render.carbone.io/render/${res.data.data.renderId}`,
            "_blank"
          );
        })
        .catch(() => (self.overlay = false));
    },
    uploadGeneticData(ev) {
      let self = this;
      self.overlay = true;
      const file = ev.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      const apiEndpoint =
        file.type == "application/pdf"
          ? "import-from-pdf-report"
          : "setup-new-user";

      axios
        .post("https://file.io", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          axios
            .post(
              `https://api.mylogygenomics.com/${
                (window.location.host == "app.mylogygenomics.com" && "v1") ||
                "dev"
              }/${apiEndpoint}/${self.currentUser.id}`,
              {
                fileUrl: res.data.link,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              self.channel.bind("results-loaded", function (data) {
                self.overlay = false;
                getMyGenotype().then((res) => self.setGenotype(res));
                getMyPGXGenes().then((res) => self.setPGXGenes(res));
              });
            });
        });
    },
    getGenesByClass(geneClass) {
      const groupBy = (items, key) =>
        items
          .sort((a, b) => {
            if (a.class < b.class) {
              return -1;
            }
            if (a.class > b.class) {
              return 1;
            }
            return 0;
          })
          .reduce(
            (result, item) => ({
              ...result,
              [item[key]]: [...(result[item[key]] || []), item],
            }),
            {}
          );
      const genes = this.filteredGenes.filter((g) =>
        g["class"].includes(geneClass)
      );

      return Object.entries(groupBy(genes, "class"));
    },
  },
};
</script>

<style lang="scss" scoped>
.gene-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 10px;
  row-gap: 0px;
  justify-items: center;
}
</style>

<style lang="scss">
fieldset {
  border-color: #4d2cb9 !important;
}
.css-tprr8g {
  height: 56px !important;
  border-radius: 50px !important;
  background: white !important;
}
.css-1ee1q46,
.css-l5274x {
  top: 15px !important;
}
.suggestions {
  position: absolute;
  z-index: 3;
  box-shadow: 0 0 10px #00000055 !important;
  //border: solid 1px#411285 !important;

  ul {
    list-style: none;
    padding-left: 0;
    max-height: 400px;
    overflow-y: overlay;
  }
  li {
    border-bottom: solid 1px #f0f0f0;
    transition: 0.2s;
    &:hover {
      background: #f0f0f0;
    }
  }
  .v-icon {
    height: 1rem;
  }
}

.sticky.filters {
  top: 100px;
}

$bg-color: #7b29ff;
@for $i from 1 through 5 {
  .gradient-bucle.row:nth-child(#{$i}) .solid-heading {
    $ptg: percentage($i/5);
    background: mix(#100029, $bg-color, $ptg);
  }
}
</style>
