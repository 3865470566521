<template>
  <reactive-base app="drugs" :url="url" :credentials="credentials" type="_doc" class="d-flex">
    <v-icon large color="white" class="mr-4 mt-1">mdi-magnify</v-icon>
    <data-search
      componentId="DrugSearchSensor"
      :placeholder="$t('buscar_por_medicamento')"
      :showClear="false"
      :showIcon="false"
      :dataField="['names', 'names.autosuggest']"
      class="grow"
    >
      <v-sheet
        class=""
        width="100%"
        slot="render"
        rounded="sm"
        elevation="16"
        slot-scope="{
          error,
          loading,
          downshiftProps: {
            isOpen,
            highlightedIndex,
            getItemProps,
            getItemEvents,
          },
          data: suggestions,
        }"
        v-bind:class="{ suggestions: isOpen }"
      >
        <ul v-if="isOpen">
          <li
            class="px-8 py-5"
            style="{ background-color: highlightedIndex ? 'grey' : 'transparent' }"
            v-for="suggestion in renderSuggestions(suggestions || [])"
            v-bind="getItemProps({ item: suggestion })"
            :key="suggestion._id"
          >
            <drug-input-search-result :drug="suggestion.value">
              {{ suggestion.label }}
            </drug-input-search-result>
          </li>
        </ul>
      </v-sheet>
    </data-search>
    <v-btn color="primary" class="d-none d-sm-inline-flex ma-auto ml-6" @click="$emit('moreFilters')"> Más filtros </v-btn>


  </reactive-base>
</template>

<script>
import DrugInputSearchResult from "@/components/mylogy/pgx_results/DrugInputSearchResult.vue";
import { CRED, TYPE, URL } from "@/helpers/constants";
import { mapGetters } from "vuex";

export default {
  components: { DrugInputSearchResult },
  data() {
    return {
      credentials: CRED,
      url: URL,
    };
  },
  computed: {
    ...mapGetters("pgx", ["getPgx", "getDrugResult"]),
  },
  methods: {
    renderSuggestions(suggestions) {
      console.log(suggestions);
      return suggestions.map((s) => {
        return {
          label: `${s.source.es} (${s.label})`,
          value: s.source,
          key: s._id,
        };
      });
    }
  },
};
</script>

<style lang="scss">
#DrugSearchSensor-input {
}

.v-sheet ul li {
  cursor: pointer;
}
</style>
