<template>
  <v-list-item @click="dialog = true" two-line class="gene-card">
    <gene-result-dialog @close="dialog = false" :active="dialog" :geneData="geneData"/>
    <v-list-item-content class="d-flex">
      <div class="mb-n1">
        {{geneData.symbol}}
        <br>
        <span v-if="displayableGenotype" style="color: gray;font-weight: lighter;">{{ geneData.genotype }}</span>
      </div>
        <v-icon :color="geneData.color" class="float-right absolute" large>mdi-circle</v-icon>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import GeneResultMixin from "@/mixins/GeneResultMixin.vue"
import GeneResultDialog from "@/components/mylogy/pgx_results/GeneResultDialog.vue"


export default {
  name: "GeneCard",
  components: {GeneResultDialog},
  mixins: [GeneResultMixin],
  data() {
    return {
      dialog: false,
      colors: ["grey", "grey", "green", "yellow", "orange", "red"]
    };
  },
  computed: {
    displayableGenotype() {
      return this.gene.genotype.includes("/");
    }
  }
};
</script>
<style lang="scss" scoped>
.gene-card {
  box-shadow:  0 5px 25px -18px #00000044, 0 -10px 20px inset #00000006;
  width: 100%;
  border: solid 2px #f3f3f3;
  
  /*Fix para a altura do ultimo item da lista*/
  max-height: 0px;
  

  .absolute {
    position: absolute;
    right: 10px;
  }
}

</style>